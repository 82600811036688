import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ProcessOfStartingACampaign = ({ location }) => {
  const title = "PARKLoTお申し込み〜キャンペーン開始までの流れ"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="インスタントウィン開始までの流れを説明します。お申し込み〜データご準備・請求書発行・お支払い〜キャンペーン作成、というのが基本的な流れです。キャンペーンの種類によってご用意いただくデータが異なります。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              キャンペーン開始までの流れを教えてください。
            </h1>
            <p className="qa-page__answer">
              お申し込み〜データのご準備・お支払い〜キャンペーン作成〜キャンペーン開始、が基本的な流れです
              <br />
            </p>
            <p className="qa-page__text">
              このページは基本プラン（インスタントウィンキャンペーン）の開始方法手順についてのご説明です。
              ハッシュタグキャンペーンなどのインスタントウィン以外のキャンペーンの開催については、
              <a
                className="qa-page__text-link"
                href="https://hub.park-lot.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせフォーム
              </a>
              からお問い合わせください。
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">キャンペーン開始までの流れ</h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>
                      <a
                        className="qa-page__text-link"
                        href="/forms/apply"
                        target="_blank"
                        rel="noreferrer"
                      >
                        お申し込みフォーム
                      </a>
                      からお申し込みください。
                    </li>
                    <li>
                      キャンペーン開催にあたり必要な情報・データをご用意ください。必要な情報・データは「Twitterアカウント・Twitter開発者アカウント・キャンペーン名・プレゼント・キャンペーン期間・ツイート投稿用の画像」の6つです。
                    </li>
                    <li>
                      キャンペーン開始の３営業日前までにお支払いいただきます。
                    </li>
                    <li>
                      お支払いを確認後、TwitterアカウントをPARKLoTでログインできるように手続きします。
                    </li>
                    <li>
                      PARKLoTにログインいただき、キャンペーンを作成していただきます。
                    </li>
                    <li>
                      開催時間になるとTwitterにツイートが自動投稿され、キャンペーンが開始します。抽選は自動で行われ、当選者は管理画面から確認することができます。
                    </li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  Twitter開発者アカウントはなぜ必要なのでしょうか？
                </h3>

                <p className="qa-page__text">
                  <a
                    className="qa-page__text-link"
                    href="https://developer.twitter.com/en/apply-for-access"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter開発者アカウント
                  </a>
                  は、Twitter
                  APIを使用してリツイートユーザの取得やダイレクトメッセージを自動送信するために必要です。
                  キャンペーンを開催するTwitterアカウントで取得していただくようにお願いしております。
                </p>

                <p className="qa-page__text">
                  Twitter開発者アカウントの申請から承諾までは３日〜2週間かかります。キャンペーン開催の2週間前には必ずご申請ください。
                </p>

                <h3 className="qa-page__h3">
                  Twitter開発者アカウントの申請方法がわかりません。
                </h3>

                <p className="qa-page__text">
                  弊社ではTwitter開発者アカウント申請代行を行なっております。お申し込みの際にお知らせください。
                </p>

                <h3 className="qa-page__h3">
                  キャンペーン画像は適当に用意しても大丈夫ですか？
                </h3>

                <p className="qa-page__text">
                  参加者はキャンペーンの画像を見てプレゼントがどんなものか？参加したら当選しそうか？を一瞬で判断しています。
                  そのため、キャンペーンの画像でキャンペーンの成功が決まると言っても過言ではありません。できるだけ時間と予算をかけて画像を作成することをお勧めいたします。
                  弊社でキャンペーン画像の代理作成も行なっております。ご希望の場合はお申し込みの際にお知らせください。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ProcessOfStartingACampaign
